import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {Button, Card, CardContent, Divider, Grid, Typography} from '@material-ui/core';
import {activateMember, deactivateMember, getMembers} from "../../api/member-api";

const Container = styled.div`
  margin: 20px;
`;

const DividerSpacing = styled(Divider)`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const MemberManagement = () => {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMembers();
    }, []);

    const fetchMembers = async () => {
        setLoading(true);
        try {
            const response = await getMembers();
            setMembers(response.data.data);
        } catch (error) {
            alert(`Failed to toggle member status: ${error.message}`);
        }
        setLoading(false);
    };

    const toggleMemberStatus = async (member) => {
        setLoading(true);
        try {
            if (member.activated) {
                await deactivateMember(member.email, member.name);
            } else {
                await activateMember(member.email, member.name);
            }

            fetchMembers();  // Refresh the list after toggling
        } catch (error) {
            alert(`Failed to toggle member status: ${error.message}`);
        }
        setLoading(false);
    };

    // Filter members by activation status
    const deactivatedMembers = members.filter(member => !member.activated);
    const activatedMembers = members.filter(member => member.activated);

    return (
        <Container>
            <Typography variant="h3" gutterBottom>
                오다비 회원 관리
            </Typography>

            <DividerSpacing/>

            <Typography variant="h4" gutterBottom>
                회원가입 요청
            </Typography>
            <Grid container spacing={2}>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    deactivatedMembers.map(member => (
                        <Grid item xs={12} sm={6} md={4} key={member.email}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">{member.name}</Typography>
                                    <Typography color="textSecondary">{member.email}</Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => toggleMemberStatus(member)}
                                    >
                                        권한 부여
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                )}
            </Grid>
            <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
                활성화된 유저들
            </Typography>
            <Grid container spacing={2}>
                {activatedMembers.map(member => (
                    <Grid item xs={12} sm={6} md={4} key={member.email}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">{member.name}</Typography>
                                <Typography color="textSecondary">{member.email}</Typography>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => toggleMemberStatus(member)}
                                >
                                    권한 제거
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default MemberManagement;
