import React, { Suspense } from 'react';
import Helmet from 'react-helmet';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import { UserProvider } from './providers/UserProvider';

import mainTheme from './theme';
import Routes from './routes/Routes';

import Loader from './components/Loader';

const App = () => {
  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Odabi LMS"
        defaultTitle="Odabi - Learning Management System"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={mainTheme[0]}>
            <ThemeProvider theme={mainTheme[0]}>
              <React.Suspense fallback={<Loader isLoading />}>
                <UserProvider>
                  <Routes />
                </UserProvider>
              </React.Suspense>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
};

export default App;
