import axios from 'axios';
// const { AuthError, BadRequestError } = require('./error');
import {Auth} from 'aws-amplify';
import {AuthError, BadRequestError} from "./error";
import httpClientConfig from './http-client.env.json';

const environment = process.env.REACT_APP_PROFILE	 || 'local'; // PROFILE 환경변수는 AWS amplify > All apps > odabi > Hosting > Environment Variables 에서 관리
const baseUrl = httpClientConfig[environment].baseUrl;

const axiosInstance = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
});

axiosInstance.interceptors.request.use();

axiosInstance.interceptors.request.use(async config => {
    try {
        const userToken = await Auth.currentAuthenticatedUser();
        config.headers.Authorization = `Bearer ${userToken.signInUserSession.idToken.jwtToken}`;
    } catch (error) {
        // Handle error if authentication fails
        console.error("Authentication error:", error);
    }
    return config;
});

axiosInstance.interceptors.response.use(
    response => response,
    err => {
        if (err.response.status === 401) {
            return Promise.reject(new AuthError('로그인이 필요합니다.'));
        }

        if (err.response.status === 403) {
            return Promise.reject(new AuthError('해당 요청에 대한 권한이 없습니다.'));
        }

        if (err.response.status >= 400 && err.response.status < 500 && err.response.status !== 413) {
            return Promise.reject(new BadRequestError(err.response.data.message));
        }

        return Promise.reject(err);
    }
);

export async function getRequest(URL, config) {
    try {
        const userToken = await Auth.currentAuthenticatedUser();
        return await axiosInstance.get(URL, {
            ...config,
            headers: {
                Authorization: `Bearer ${userToken.signInUserSession.idToken.jwtToken}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
    } catch (error) {
        console.error("getRequest error:", error);
        throw error;
    }
}

export async function getRequestWithoutAuth(URL, config) {
    try {
        return await axiosInstance.get(URL, {
            ...config,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
    } catch (error) {
        console.error("getRequest error:", error);
        throw error;
    }
}

export async function postRequest(URL, payload, config) {
    try {
        const userToken = await Auth.currentAuthenticatedUser();
        return await axiosInstance.post(
            URL,
            payload,
            {...config,
            headers: {
                Authorization: `Bearer ${userToken.signInUserSession.idToken.jwtToken}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
    } catch (error) {
        console.error("postRequest error:", error);
        throw error;
    }
}

export async function putRequest(URL, payload, config) {
    try {
        const userToken = await Auth.currentAuthenticatedUser();
        return await axiosInstance.put(
            URL,
            payload,
            { ...config,
            headers: {
                Authorization: `Bearer ${userToken.signInUserSession.idToken.jwtToken}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
    } catch (error) {
        console.error("putRequest error:", error);
        throw error;
    }
}

export async function deleteRequest(URL, config) {
    try {
        const userToken = await Auth.currentAuthenticatedUser();
        return await axiosInstance.delete(URL, {
            ...config,
            headers: {
                Authorization: `Bearer ${userToken.signInUserSession.idToken.jwtToken}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
    } catch (error) {
        console.error("deleteRequest error:", error);
        throw error;
    }
}
