import React, { useState, useEffect, useMemo, createContext, useContext } from 'react';
import { Auth, Hub } from 'aws-amplify';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ user: null, loading: true });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUser({ user: user, loading: false });
      })
      .catch(e => {
        console.log(e);
        setUser({ user: null, loading: false });
      });
    Hub.listen('auth', data => {
      if (data.payload.event === 'signIn') {
        // console.log(data.payload.data);
      }
      if (data.payload.event === 'signOut') setUser({ user: null, loading: false });
    });
  }, []);

  const singin = async (usernameOrEmail, password) => {
    setUser({ user: null, loading: true });
    await Auth.signIn(usernameOrEmail, password)
      .then(cognitoUser => {
        const userGroup =
          cognitoUser?.signInUserSession?.accessToken.payload['cognito:groups'] || [];
        if (userGroup.includes('admin') || userGroup.includes('customer')) {
          setUser({ user: cognitoUser, loading: false });
        } else {
          Auth.signOut();
          alert('관리자 승인이 필요합니다.');
          setUser({ user: null, loading: false });
        }
        console.log(cognitoUser);
        return cognitoUser;
      })
      .catch(err => {
        console.log(err);
        if (err.code === 'UserNotConfirmedException') {
          err.message = 'Not verify user';
        } else if (err.code === 'NotAuthorizedException' || err.code === 'UserNotFoundException') {
          // alert('로그인에 실패했습니다.');
          err.message = 'Invalid username or password';
        }
        setUser({ user: null, loading: false });
        throw err;
      });
  };

  const singout = () =>
    Auth.signOut().then(data => {
      setUser({ user: null, loading: false });
      return data;
    });

  const values = useMemo(() => ({ user, singin, singout }), [user]);

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUserConsumer = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component');
  }
  return context;
};
