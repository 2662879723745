import {getRequest, putRequest} from "../connector/axios-client";

export async function getMembers() {
    return await getRequest(
        `/members`
    );
}

export async function activateMember(userEmail, userName) {
    return await putRequest(
        `/members/activate`,
        {
            email: userEmail,
            name: userName
        }
    );
}

export async function deactivateMember(userEmail, userName) {
    return await putRequest(
        `/members/deactivate`,
        {
            email: userEmail,
            name: userName
        }
    );
}

