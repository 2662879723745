import React from 'react';
import styled from 'styled-components';

import {
  Grid,
  Hidden,
  List,
  ListItemText,
  ListItem as MuiListItem,
} from '@material-ui/core';

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(1) / 4}px
  ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.palette.common.white};
  position: relative;
  @media (max-width: 960px) {
    display: none;
  }
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${props => props.theme.spacing(2)}px;
  padding-right: ${props => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem component="span">
                <ListItemText primary="주식회사 고투무브" />
              </ListItem>
              <ListItem component="span">
                <ListItemText primary="소재지: 서울특별시 강남구 테헤란로86길 15, 305호 (대치동, 동구빌딩)" />
              </ListItem>
              <ListItem component="span">
                <ListItemText primary="대표이사 : 이용진" />
              </ListItem>
              <ListItem component="span">
                <ListItemText primary="사업자 등록번호 : 363-88-01271" />
              </ListItem>
              <ListItem component="span">
                <ListItemText primary="통신판매업 신고번호: 제2021-서울강남-02362호" />
              </ListItem>
              <ListItem component="span">
                <ListItemText primary="E-mail : odabi@odabi.net" />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem>
              <ListItemText
                primary={`© ${new Date().getFullYear()} ©Go-to Move Inc. All Rights Reserved.`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
