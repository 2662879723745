import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress, Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  backdrop: props => ({
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    minHeight: '100%',
    [theme.breakpoints.up('sm')]: {
      // left: (props.fullWidth) ? 0 : '260px'
    },
    zIndex: theme.zIndex.drawer + 1,
    color: props.color,
    background: 'none',
  }),
}));

/**
 * Loader Component
 */
const Loader = props => {
  const { isLoading, ...restProps } = props;
  const classes = useStyles(restProps);
  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

Loader.propTypes = {
  /**
   * 로딩창 활성 여부
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * 로딩바 색상
   */
  color: PropTypes.string,
};

Loader.defaultProps = {
  isLoading: true,
  color: '#1976d2',
};

export default Loader;
