import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {
  dashboard as dashboardRoutes,
  auth as authRoutes,
  report as reportRoutes,
} from './index';
import { useUserConsumer } from '../providers/UserProvider';

import DashboardLayout from '../layouts/Dashboard';
import AuthLayout from '../layouts/Auth';
import ReportLayout from '../layouts/Report';
import Page404 from '../pages/auth/Page404';
import Loader from '../components/Loader';

// user.signInUserSession.accessToken.payload["cognito:groups"]

const childRoutesWithAuth = (Layout, routes, user) => {
  const userGroup =
    user?.user?.signInUserSession?.accessToken.payload['cognito:groups'] || [];
  return routes.map(({ children, path, component: Component }, index) =>
    children ? (
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props =>
            !user.loading ? (
              userGroup.includes('admin') || userGroup.includes('customer') ? (
                <Layout>
                  <Component {...props} user={user?.user} />
                </Layout>
              ) : (
                <Redirect
                  to={{
                    pathname: '/auth/signin',
                    state: { from: props.location },
                  }}
                />
              )
            ) : (
              <Loader isLoading />
            )
          }
        />
      ))
    ) : (
      <Route
        key={index}
        path={path}
        exact
        render={props =>
          !user.loading ? (
            userGroup.includes('admin') || userGroup.includes('customer') ? (
              <Layout>
                <Component {...props} user={user?.user} />
              </Layout>
            ) : (
              <Redirect
                to={{
                  pathname: '/auth/signin',
                  state: { from: props.location },
                }}
              />
            )
          ) : (
            <Loader isLoading />
          )
        }
      />
    ),
  );
};

const childRoutesPublic = (Layout, routes, user) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} user={user?.user} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} user={user?.user} />
          </Layout>
        )}
      />
    ),
  );

const Routes = props => {
  const { user } = useUserConsumer();
  return (
    <Router>
      <Switch>
        {childRoutesWithAuth(DashboardLayout, dashboardRoutes, user)}
        {childRoutesPublic(AuthLayout, authRoutes, user)}
        {childRoutesPublic(ReportLayout, reportRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
