import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { spacing } from "@material-ui/system";
import { CssBaseline, Paper as MuiPaper } from "@material-ui/core";
import Footer from "../components/Footer";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const Spacer = styled.div`
  flex: 1 0;
  height: 61px;
`;

const Paper = styled(MuiPaper)(spacing);
const MainContent = styled(Paper)`
  flex: 1;
  display: inline-flex;
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background: none;
  box-shadow: none;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

function Auth({ children }) {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <MainContent>{children}</MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
}

export default Auth;
