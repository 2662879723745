import React from 'react';
import Async from '../components/Async';

import {
  Apps,
  People,
  AssignmentInd,
  ImportContacts,
  Edit,
  PeopleOutline
} from '@material-ui/icons';
import MemberConfirm from "../pages/member/MemberConfirm";

// Auth components
const SignInComponent = Async(() => import('../pages/auth/SignIn'));
const SignUpComponent = Async(() => import('../pages/auth/SignUp'));
const ResetPasswordComponent = Async(() => import('../pages/auth/ResetPassword'));
const VerifyComponent = Async(() => import('../pages/auth/Verify'));
const PasswordVerifyComponent = Async(() => import('../pages/auth/PasswordVerify'));
const Page404Component = Async(() => import('../pages/auth/Page404'));
const Page500Component = Async(() => import('../pages/auth/Page500'));

// Pages components
const BlankComponent = Async(() => import('../pages/Blank'));
const DashboardComponent = Async(() => import('../pages/dashboards/Dashboard'));

// Student components
const StudentListComponent = Async(() => import('../pages/students/StudentList'));
const StudentAddComponent = Async(() => import('../pages/students/StudentAdd'));
const StudentEditComponent = Async(() => import('../pages/students/StudentEdit'));
const StudentDetailComponent = Async(() => import('../pages/students/StudentDetail'));
const StudentImportComponent = Async(() => import('../pages/students/StudentImport'));

// Class components
const ClassListComponent = Async(() => import('../pages/classes/ClassList'));
const ClassAddComponent = Async(() => import('../pages/classes/ClassAdd'));
const ClassCurriculumEditComponent = Async(() => import('../pages/classes/ClassEdit'));
const ClassStudentEnrollment = Async(() => import('../pages/classes/ClassStudentEnrollment'));
const ClassReportListComponent = Async(() => import('../pages/classes/ClassReportList'));
const ClassReportAddComponent = Async(() => import('../pages/classes/ClassReportAdd'));
const ClassReportEnterComponent = Async(() => import('../pages/classes/ClassReportEnter'));
const ClassReportEditComponent = Async(() => import('../pages/classes/ClassReportEdit'));

// Paper components
const PaperListComponent = Async(() => import('../pages/papers/PaperList'));
const PaperAddComponent = Async(() => import('../pages/papers/PaperAdd'));
const PaperUploadComponent = Async(() => import('../pages/papers/PaperUpload'));
const PaperEditComponent = Async(() => import('../pages/papers/PaperEdit'));

// Test components
const TestListComponent = Async(() => import('../pages/tests/TestList'));
const TestAddComponent = Async(() => import('../pages/tests/TestAdd'));
const TestEditComponent = Async(() => import('../pages/tests/TestEdit'));
const TestReportComponent = Async(() => import('../pages/tests/TestReport'));

// Report components
const ReportComponent = Async(() => import('../pages/reports/Report'));
const ReportDetailComponent = Async(() => import('../pages/reports/ReportDetail'));

// 인증 라우터
const authRoutes = {
  id: 'Auth',
  path: '/auth',
  icon: <People />,
  children: [
    {
      path: '/auth/signin',
      name: 'Sign In',
      component: SignInComponent,
    },
    {
      path: '/auth/signup',
      name: 'Sign Up',
      component: SignUpComponent,
    },
    {
      path: '/auth/resetpassword',
      name: 'Reset Password',
      component: ResetPasswordComponent,
    },
    {
      path: '/auth/verify',
      name: 'Verify Email',
      component: VerifyComponent,
    },
    {
      path: '/auth/passwordverify',
      name: 'Verify Password Reset',
      component: PasswordVerifyComponent,
    },
    {
      path: '/auth/404',
      name: '404 Page',
      component: Page404Component,
    },
    {
      path: '/auth/500',
      name: '500 Page',
      component: Page500Component,
    },
  ],
};

// 대쉬보드 라우터
const homeRoutes = {
  id: '대쉬보드',
  path: '/',
  header: 'Pages',
  icon: <Apps />,
  containsHome: true,
  component: DashboardComponent,
  children: null,
};

// 학생 관리 라우터
const studentRoutes = {
  id: '학생 관리',
  icon: <People />,
  children: [
    {
      path: '/student',
      name: '학생 목록',
      component: StudentListComponent,
    },
    {
      path: '/student/add',
      name: '학생 추가',
      component: StudentAddComponent,
    },
    {
      path: '/student/import',
      name: '학생 목록 업로드',
      component: StudentImportComponent,
    },
  ],
};
const studentEditRoutes = {
  id: '학생 수정',
  path: '/student/:studentID/edit',
  component: StudentEditComponent,
  children: null,
};

const studentDetailRoutes= {
  id: '학생 상세',
  path: '/student/detail/:studentID',
  component: StudentDetailComponent,
  children: null,
};

// 클래스 관리 라우터
const classRoutes = {
  id: '클래스 관리',
  icon: <AssignmentInd />,
  children: [
    {
      path: '/class',
      name: '클래스 목록',
      component: ClassListComponent,
    },
    {
      path: '/class/add',
      name: '클래스 추가',
      component: ClassAddComponent,
    },
  ],
};
const classStudentEnrollment = {
  id: '수강생 관리',
  path: '/class/:classID/enrollment',
  component: ClassStudentEnrollment,
  children: null,
};
const classCurriculumEditRoutes = {
  id: '커리큘럼 수정',
  path: '/class/:classID/curriculum',
  component: ClassCurriculumEditComponent,
  children: null,
};
const classReportListRoutes = {
  id: '성적표 목록',
  path: '/class/:classID/report',
  component: ClassReportListComponent,
  children: null,
};
const classReportAddRoutes = {
  id: '성적표 생성',
  path: '/class/:classID/report/add',
  component: ClassReportAddComponent,
  children: null,
};
const classReportEnterRoutes = {
  id: '성적표 입력',
  path: '/class/:classID/report/:reportID/enter',
  component: ClassReportEnterComponent,
  children: null,
};
const classReportEditRoutes = {
  id: '성적표 수정',
  path: '/class/:classID/report/:reportID/edit',
  component: ClassReportEditComponent,
  children: null,
};

// 문제지 관리 라우터
const paperRoutes = {
  id: '문제지 관리',
  icon: <ImportContacts />,
  children: [
    {
      path: '/paper',
      name: '문제지 목록',
      component: PaperListComponent,
    },
    {
      path: '/paper/add',
      name: '문제지 추가',
      component: PaperAddComponent,
    },
    {
      path: '/paper/upload',
      name: '문제지 업로드',
      component: PaperUploadComponent,
    },
  ],
};
const paperEditRoutes = {
  id: '문제지 수정',
  path: '/paper/:paperID/edit',
  component: PaperEditComponent,
  children: null,
};

// 시험 관리 라우터
const testRoutes = {
  id: '시험 관리',
  icon: <Edit />,
  children: [
    {
      path: '/test',
      name: '시험 목록',
      component: TestListComponent,
    },
    {
      path: '/test/add',
      name: '시험 추가',
      component: TestAddComponent,
    },
  ],
};
const testEditRoutes = {
  id: '시험 수정',
  path: '/test/:testID/edit',
  component: TestEditComponent,
  children: null,
};
const testReportRoutes = {
  id: '시험 리포트',
  path: '/test/:testID',
  component: TestReportComponent,
  children: null,
};

// 성적표 관리 라우터
const ReportRoutes = {
  id: '클래스 학생 성적표 조회',
  path: '/class/:classID/:studentID/report',
  icon: <People />,
  component: ReportComponent,
  children: null,
};
const ReportDetailRoutes = {
  id: '클래스 학생 성적표 상세 조회',
  path: '/class/:classID/:studentID/report/:curriculumID',
  icon: <People />,
  component: ReportDetailComponent,
  children: null,
};

// 회원 관리 라우터
const memberManagementRoutes = {
  id: '오다비 회원 관리',
  path: '/member/management',
  icon: <PeopleOutline />,
  auth: ['admin'],
  component: MemberConfirm,
  children: null,
};

export const dashboard = [
  homeRoutes,

  studentRoutes,
  studentEditRoutes,
  studentDetailRoutes,

  classRoutes,
  classStudentEnrollment,
  classCurriculumEditRoutes,
  classReportListRoutes,
  classReportAddRoutes,
  classReportEditRoutes,
  classReportEnterRoutes,

  paperRoutes,
  paperEditRoutes,

  testRoutes,
  testEditRoutes,
  testReportRoutes,

  memberManagementRoutes,
];

export const auth = [authRoutes];
export const report = [ReportRoutes, ReportDetailRoutes];

export default [homeRoutes, studentRoutes, classRoutes, paperRoutes, testRoutes, memberManagementRoutes];
